import 'bootstrap/dist/css/bootstrap.css';
import credpallogo2 from "../../assets/credpallogo2.png";
import credpalLogo2 from "../../assets/credpalLogo2White.png"
// import futube from "../../assets/futube.png";
// import fwsapp from "../../assets/fwsapp.png";
// import flinkdin from "../../assets/flinkdin.png";
// import fttok from "../../assets/fttok.png";

import "../../styles/index.css"



function FooterPage() {
  return (
    <>
        {/* <footer style={{backgroundColor:'#1B1F27', marginTop:10}} className="footer py-3 py-xl-8">
            <div className="container">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-11">

                            <section className="py-4 py-md-5 py-xl-8">
                                <div >
                                    <div className="row gy-4 gy-lg-0 justify-content-xl-between">
                                        <div className="col-12 col-md-4 col-lg-3 col-xl-2">
                                            <div >
                                                <a href="#!">
                                                <img src={credpallogo2} width="150" height="50" alt="credpal" />
                                                </a>

                                                <div style={{marginTop:10}} className="social-media-wrapper  ">
                                                    <ul className="m-0 list-unstyled d-flex  gap-3">

                                                    <li >
                                                        <a href="#!">
                                                          <img loading="lazy" src={futube} alt="utube"  />
                                                        </a>
                                                    </li>
                                                    <li >
                                                        <a href="#!">
                                                            <img loading="lazy" src={fwsapp} alt="wsapp" />
                                                        </a>
                                                    </li>
                                                    <li >
                                                        <a href="#!">
                                                            <img loading="lazy" src={flinkdin} alt="linkedin" />
                                                        </a>
                                                    </li>
                                                    <li >
                                                        <a href="#!">
                                                            <img loading="lazy" src={fttok} alt="tiktok" />
                                                        </a>
                                                    </li>
                                                   
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                        <div  className="col-12 col-md-4 col-lg-3 col-xl-2">
                                            <div className="widget">
                                                <h4 className=" text-light widget-title mb-4">Company</h4>
                                                <ul className="list-unstyled">
                                                <li className="mb-2">
                                                    <a href="#!" className="link-secondary text-light text-decoration-none">About</a>
                                                </li>
                                                <li className="mb-2">
                                                    <a href="#!" className="link-secondary text-light text-decoration-none">Our Mission</a>
                                                </li>
                                                <li className="mb-2">
                                                    <a href="#!" className="link-secondary text-light text-decoration-none">Communities</a>
                                                </li>
                                                
                                                </ul>
                                            </div>
                                        </div>


                                        <div className="col-12 col-md-4 col-lg-3 col-xl-2">
                                            <div className="widget">
                                                <h4 className="text-light widget-title mb-4">Features</h4>
                                                <ul className="list-unstyled">
                                                <li className="mb-2">
                                                    <a href="#!" className="link-secondary text-light text-decoration-none">Recipies</a>
                                                </li>
                                                <li className="mb-2">
                                                    <a href="#!" className="link-secondary text-light text-decoration-none">Meal Planner</a>
                                                </li>
                                                <li className="mb-2">
                                                    <a href="#!" className="link-secondary text-light text-decoration-none">Communities</a>
                                                </li>
                                            
                                                </ul>
                                            </div>
                                        </div>


                                        <div className="col-12 col-md-4 col-lg-3 col-xl-2">
                                            <div className="widget">
                                                <h4 className="text-light widget-title mb-4">Resources</h4>
                                                <ul className="list-unstyled">
                                                <li className="mb-2">
                                                    <a href="#!" className="link-secondary text-light text-decoration-none">Blog</a>
                                                </li>
                                                <li className="mb-2">
                                                    <a href="#!" className="link-secondary text-light text-decoration-none">Nutrition Calculator</a>
                                                </li>
                                                <li className="mb-2">
                                                    <a href="#!" className="link-secondary text-light text-decoration-none">Dietary Facts</a>
                                                </li>
                                            
                                                </ul>
                                            </div>
                                        </div>


                                        

                                    </div>
                                </div>
                            </section>
                    
                        </div>
                    </div>
                </div>
            </div>




            <div style={{marginTop:5}} className="credit-wrapper d-block text-secondary fs-10 text-start text-sm-center">
                                            <div className="link-wrapper">
                                                <ul className="m-0 list-unstyled d-flex flex-wrap justify-content-center gap-2 gap-md-4">
                                                <li>
                                                    <a href="#!" className="link-secondary text-light text-decoration-none">
                                                        © 2024 Recipes from Nigeria. All rights reserved.
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" className="link-secondary text-light text-decoration-none">
                                                        Privacy Policy
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" className="link-secondary text-light text-decoration-none">
                                                        Terms
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" className="link-secondary text-light text-decoration-none">
                                                        Security
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!" className="link-secondary text-light text-decoration-none">
                                                        Do not sell my data
                                                    </a>
                                                </li>
                                                </ul>
                                            </div>
                                        </div>
        </footer> */}


       {/* <-- Laptop ---> */}
       <footer className="footer"  style={{backgroundColor:'#1B1F27',  textAlign:'center', marginTop:"-3%"}} >
            <div >
                <a href="#!">
                    <img style={{marginTop:"5%", marginBottom:"3%"}}  src={credpalLogo2} width="200" height="40" alt="credpal" />
                </a>
            </div>
            <div >
                <hr style={{border:"1px solid white"}} />
                <p style={{color:"white", fontSize:21,  fontWeight:'normal',}} >
                    © 2024  Peakpoint Innovations Limited. All rights reserved.
                </p>
                <br />
                <br />
            </div>

        </footer>
        {/* <-- End Of Laptop ---> */}

 
       
        {/* <-- Show on Mobile, Hide on Laptop ---> */}
        <footer className="footer-mb"  style={{backgroundColor:'#1B1F27',  textAlign:'center'}} >
            <div >
                <a href="#!">
                    <img style={{marginTop:"6%", marginBottom:"5%"}}  src={credpalLogo2} width="170" height="30" alt="credpal" />
                </a>
            </div>
            <div>
                <hr style={{border:"1px solid white"}} />
                <p style={{color:"white", fontSize:"45", }} >
                    © 2024  PeakPoint Inc. All rights reserved.
                </p>
                <br/>
            </div>
        </footer>





        
     

    </>
  );
}

export default FooterPage;


