import React, { useState} from "react";
import 'bootstrap/dist/css/bootstrap.css';
//https://www.npmjs.com/package/react-dropdown-select
import Select from "react-dropdown-select";

//https://www.npmjs.com/package/react-search-box
import ReactSearchBox from "react-search-box";



import { customers } from './data';

function DashboardTable() {
    const [search, setSearch] = React.useState("");

    const options = [
        {
          id: 1,
          name: 'Newest'
        },
        {
          id: 2,
          name: 'Oldest'
        }
      ];

  return (
    <>
    <div style={{backgroundColor:"white", marginTop:"5%", padding:30, borderRadius:10}} className="container text-center">
        <div style={{textAlign:"left", lineHeight:0.8}}>
            <p style={{ marginTop:30, fontWeight:"bold", fontSize:22}}>
                    All Customers
            </p>

            <p style={{ fontWeight:600, fontSize:14, color:'#51D27D'}}>
                Active Members
            </p>

            <p>
                <span style={{color:"696F8C",fontSize:14}}>Filter Applied. 5/49 fields shown.</span>
                <span style={{color:"#3366FF", fontSize:14}}> Clear filter</span>
            </p>
        </div>

        <div>
            <ReactSearchBox style={{width:155, height: 21 }}
                placeholder="Search by items"
                value="Doe"
                data={customers}
                callback={(record) => console.log(record)}
            />
            <Select style={{width:200, height: 21 }}
                        options={options}
                        labelField="name"
                        valueField="id"
                        onChange={(values) => {setSearch(values.id)} }
                        placeholder="Sort by: Newest"
            />
        </div>
    
        

        
        

        <table className="table table-sm table-hover table-bordered">
            <thead >
                <tr >
                <th scope="col" style={{fontSize:14, color:"#B5B7C0", padding:20}}>Customer ID</th>
                <th scope="col" style={{fontSize:14, color:"#B5B7C0", padding:20}}>Email address</th>
                <th scope="col" style={{fontSize:14, color:"#B5B7C0", padding:20}}>Meal preference</th>
                <th scope="col" style={{fontSize:14, color:"#B5B7C0", padding:20}}>Gender</th>
                <th scope="col" style={{fontSize:14, color:"#B5B7C0", padding:20}}>Engagements</th>
                <th scope="col" style={{fontSize:14, color:"#B5B7C0", padding:20}}>Status</th>
                <th scope="col" style={{fontSize:14, color:"#B5B7C0", padding:20}}>Date joined</th>
                
                </tr>
            </thead>
            <tbody>
                {customers.map(u => (
                    <tr key={u.key}>
                        <td style={{padding:20, fontSize:14}}> {u.id}  </td>
                        <td style={{padding:20, fontSize:14}}><span className="fw-normal"><div className="small text-gray">{u.email}</div></span></td>
                        <td style={{padding:20, fontSize:14}}><span className="fw-normal"><div className="small text-gray">{u.mealPreference}</div></span></td>
                        <td style={{padding:20, fontSize:14}}><span className="fw-normal">{u.gender}</span></td>
                        <td style={{padding:20,fontSize:14}}><span className="fw-normal">{u.engagement}</span></td>
                        <td style={{padding:20, fontSize:14}}><span className="fw-normal">{u.status}</span></td>
                        <td style={{padding:20, fontSize:14}}><span className="fw-normal">{u.dateJoined}</span></td>
                    </tr>
                ))}
                
            </tbody>
        </table>
        
    
    </div>
    </>
  );
}

export default DashboardTable;


