import 'bootstrap/dist/css/bootstrap.css';
import adddark from "../../assets/adddark.png";
import "../../styles/index.css"

function FeaturesPage() {
  return (
    <>
        <div style={{marginTop:"3%"}} className="features py-3 py-md-5 text-center container">
            <h1  style={{fontSize:21, color:"#009D71", fontWeight:"500"}} >FEATURES</h1>
            <div>
                <p  style={{ fontWeight: 500}} className=" mx-auto display-4 mb-4" >
                    Supporting Healthy Meal Plans 
                    <br  />That Fits Your Eating Habit<img className="position-absolute" src={adddark} style={{marginLeft: -10,  marginTop: -5}} alt="+" />
                </p>
            </div>
        </div>


         {/* <-- Show on Mobile, Hide on Laptop ---> */}
        <div className="features-mb py-3 py-md-5 text-center container">
            <h1  style={{fontSize:18, color:"#009D71", fontWeight:"500", marginTop:"5%"}} >FEATURES</h1>
            <div>
                <p  style={{ fontWeight: 500, fontSize:21, marginTop:"5%"}} className=" mx-auto  mb-4" >
                    Supporting Healthy Meal Plans 
                    <br  />That Fits Your Eating Habit
                </p>
            </div>
        </div>
        {/* <-- End Of Show on Mobile, Hide on Laptop ---> */}
            

    </>
  );
}

export default FeaturesPage;


