import 'bootstrap/dist/css/bootstrap.css';
//https://bootstrapbrain.com/component/bootstrap-table-card-snippet/#preview
import topRecipe from "../../assets/topRecipe.png";

import { topRecipes } from './data';


function DashboardRecipeTable() {
  return (
    <>
        <section  style={{marginTop:"-5%"}} className="py-3 py-md-5">
        <div className="container">
            <div className="row justify-content-center">
            <div className="col-12 col-lg-9 col-xl-12">
                <div className="card widget-card border-light shadow-sm">
                <div className="card-body p-4">
                    <div style={{lineHeight:0.5, marginTop:"2%"}}>
                        <p style={{color:"#2D3748", fontSize:18, fontWeight:600}} className="card-title widget-card-title mb-4">Top Recipes</p>
                        <div style={{display:'flex', flexDirection:"row",}}>
                            <img style={{marginTop:-1.2, marginRight:7}} className="h-50" src={topRecipe} alt="✓" />
                            <p style={{color:"#A0AEC0", fontSize:14, fontWeight:700}} className="mt-0 mb-4">140 meals planned <span style={{color:"#A0AEC0", fontSize:14, fontWeight:400}}>this month</span></p>
                        </div>
                    </div>
                    
                    <div className="table-responsive">
                    <table className="table table-border table-hover bsb-table-xl text-nowrap align-middle m-0">
                        <thead>
                        <tr>
                            <th style={{fontSize:10, fontWeight:700, color:"#A0AEC0"}}>RECIPES</th>
                            <th style={{fontSize:10, fontWeight:700, color:"#A0AEC0"}}>MEAL PLANNED</th>
                            <th style={{fontSize:10, fontWeight:700, color:"#A0AEC0"}}>REVIEWS</th>
                            <th style={{fontSize:10, fontWeight:700, color:"#A0AEC0"}}>SAVE RATE</th>
                            {/* <th>Budget</th> */}
                        </tr>
                        </thead>
                        <tbody>
                            {topRecipes.map(u => (
                                <tr key={u.key}>
                                    <td style={{padding:20, fontSize:14, marginRight:"-5%"}}>
                                            <div style={{ fontSize:14, fontWeight:700, color:"#2D3748", display:"flex",}} className="small text-gray">
                                                <img  style={{height:"5%", width:"5%", marginRight:"2%"}} src="https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg" className="avatar  avatar-sm rounded-circle " alt="user1" />
                                                   <span> {  u.recipes} </span>
                                            </div>
                                    </td>
                                    <td style={{padding:20, fontSize:14, fontWeight:700, color:"#2D3748", marginLeft:"-5%"}}>
                                        <div className="fw-bold text-gray">{u.mealPlanned}</div>
                                    </td>
                                    <td className="fw-bold" style={{padding:20, fontSize:14, fontWeight:700, color:"#30B15C"}}>
                                        {u.reviews}
                                    </td>
                                    <td className="fw-normal" style={{padding:20, fontSize:14, fontWeight:700, color:"#3366FF"}}>
                                        <span className="fw-bold">{u.saveRate}</span>
                                        {/* <div className="col-12 col-sm-6"> */}
                    {/* <div className="card border-0">
                      <div className="card-body">
                        <h6 className="mb-3">Brazil</h6> */}

                                        <div style={{height:3.5, }} className="progress">
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{ width: '60%', height:3.5, backgroundColor:"#3366FF" }}
                                                aria-valuenow="60"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            ></div>
                                        </div>

                        {/* <div style={{color:"#3366FF"}} className="progress skill-progress">
                  <div style={{color:"#3366FF"}} className="progress-bar progress-bar-striped progress-bar-animated progress-bar-width-3" role="progressbar" aria-label="Vue" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100">65%</div>
                </div> */}
                        {/* <div style={{width:"100%", height:"10%", color:"#3366FF"}} className="progress" role="progressbar" aria-label="Brazil Visitors" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                          <div className="progress-bar" style={{width: "auto", color:"#3366FF", height:"5%", }}>17%</div>
                        </div> */}
                      {/* </div> */}
                    {/* </div>
                  </div> */}
                                    </td>
                                </tr>
                            ))}
                        {/* <tr>
                            <td>
                            <div className="d-flex align-items-center">
                                <span className="fs-6 bsb-w-35 bsb-h-35 text-bg-primary rounded-circle d-flex align-items-center justify-content-center me-2">
                                <i className="bi bi-twitter-x"></i>
                                </span>
                                <div>
                                <h6 className="m-0">Twitter</h6>
                                <span className="text-secondary fs-7">SMM</span>
                                </div>
                            </div>
                            </td>
                            <td>
                            <h6 className="mb-1">Oliver</h6>
                            <span className="text-secondary fs-7">United States</span>
                            </td>
                            <td>
                            <h6 className="mb-1">Bootstrap</h6>
                            <span className="text-secondary fs-7">v5.3+</span>
                            </td>
                            <td>
                            <span className="badge bg-danger bsb-w-85">Pending</span>
                            </td>
                            <td>$495</td>
                        </tr>
                        <tr>
                            <td>
                            <div className="d-flex align-items-center">
                                <span className="fs-6 bsb-w-35 bsb-h-35 text-bg-success rounded-circle d-flex align-items-center justify-content-center me-2">
                                <i className="bi bi-facebook"></i>
                                </span>
                                <div>
                                <h6 className="m-0">Facebook</h6>
                                <span className="text-secondary fs-7">PPC</span>
                                </div>
                            </div>
                            </td>
                            <td>
                            <h6 className="mb-1">Emma</h6>
                            <span className="text-secondary fs-7">United Kingdom</span>
                            </td>
                            <td>
                            <h6 className="mb-1">WordPress</h6>
                            <span className="text-secondary fs-7">v6.3+</span>
                            </td>
                            <td>
                            <span className="badge bg-success bsb-w-85">Paid</span>
                            </td>
                            <td>$950</td>
                        </tr>
                        <tr>
                            <td>
                            <div className="d-flex align-items-center">
                                <span className="fs-6 bsb-w-35 bsb-h-35 text-bg-danger rounded-circle d-flex align-items-center justify-content-center me-2">
                                <i className="bi bi-github"></i>
                                </span>
                                <div>
                                <h6 className="m-0">Github</h6>
                                <span className="text-secondary fs-7">PPC</span>
                                </div>
                            </div>
                            </td>
                            <td>
                            <h6 className="mb-1">Isabella</h6>
                            <span className="text-secondary fs-7">Canada</span>
                            </td>
                            <td>
                            <h6 className="mb-1">React</h6>
                            <span className="text-secondary fs-7">v18+</span>
                            </td>
                            <td>
                            <span className="badge bg-info bsb-w-85">Paused</span>
                            </td>
                            <td>$700</td>
                        </tr>
                        <tr>
                            <td>
                            <div className="d-flex align-items-center">
                                <span className="fs-6 bsb-w-35 bsb-h-35 bg-info text-white rounded-circle d-flex align-items-center justify-content-center me-2">
                                <i className="bi bi-youtube"></i>
                                </span>
                                <div>
                                <h6 className="m-0">YouTube</h6>
                                <span className="text-secondary fs-7">SMM</span>
                                </div>
                            </div>
                            </td>
                            <td>
                            <h6 className="mb-1">William</h6>
                            <span className="text-secondary fs-7">UAE</span>
                            </td>
                            <td>
                            <h6 className="mb-1">Vue</h6>
                            <span className="text-secondary fs-7">v3+</span>
                            </td>
                            <td>
                            <span className="badge bg-warning bsb-w-85">Active</span>
                            </td>
                            <td>$875</td>
                        </tr>
                        <tr>
                            <td>
                            <div className="d-flex align-items-center">
                                <span className="fs-6 bsb-w-35 bsb-h-35 bg-warning text-white rounded-circle d-flex align-items-center justify-content-center me-2">
                                <i className="bi bi-google"></i>
                                </span>
                                <div>
                                <h6 className="m-0">Google</h6>
                                <span className="text-secondary fs-7">SEM</span>
                                </div>
                            </div>
                            </td>
                            <td>
                            <h6 className="mb-1">Preston</h6>
                            <span className="text-secondary fs-7">Australia</span>
                            </td>
                            <td>
                            <h6 className="mb-1">Svelte</h6>
                            <span className="text-secondary fs-7">v4+</span>
                            </td>
                            <td>
                            <span className="badge bg-primary bsb-w-85">Closed</span>
                            </td>
                            <td>$1230</td>
                        </tr> */}


                        </tbody>
                    </table>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>

    </>
  );
}

export default DashboardRecipeTable;





// import 'bootstrap/dist/css/bootstrap.css';
// //https://www.educba.com/bootstrap-vertical-menu/
// //https://stackoverflow.com/questions/48248975/bootstrap-navbar-collapse-dont-work-react-create-react-app
// // https://getbootstrap.com/docs/4.0/components/navbar/

// import credpalLogo from "../../assets/credpalLogo.png";


// function DashboardNavBar() {
//   return (
//     <>
//         <div className="row">
//             <div className="col-3">
//                 <nav class ="navbar bg-light">
//                     <button className="navbar-brand navbar-toggler" data-toggle="collapse" data-target="#colp" href="#">
//                         <img src={credpalLogo}  className="d-inline-block align-top" alt="credpalLogo" />
//                     </button>
//                     {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#colp">
//                      <span className="navbar-toggler-icon" style={{backgroundColor:"darkblue"}}></span>
//                     </button> */}
//                     <div className="collapse navbar-collapse" id="colp">
//                         <ul class ="nav navbar-nav">
//                             <li class ="nav-item">
//                               <a class ="nav-link" href="#"> Dashboard </a>
//                             </li>
//                             <li class ="nav-item">
//                              <a class ="nav-link" href="#"> Users </a>
//                             </li>
//                             <li class ="nav-item">
//                              <a class ="nav-link" href="#"> All-stars </a>
//                             </li>
//                             <li class ="nav-item">
//                                 <a class ="nav-link" href="#"> Content Creators </a>
//                             </li>


//                             <li className="nav-item dropdown">
//                                 <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//                                 Dropdown link
//                                 </a>
//                                 <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
//                                 <a className="dropdown-item" href="#">Action</a>
//                                 <a className="dropdown-item" href="#">Another action</a>
//                                 <a className="dropdown-item" href="#">Something else here</a>
//                                 </div>
//                             </li>
//                         </ul>
//                     </div>
//                 </nav>
//             </div>

//             <div className="col-9">
//                 <div className="container">
//                  The Web Content in detail.
//                 </div>
//                 <div class ="container">
//                     <p> The vertical menu can place the left or right side of the web pages. <br /> But the default vertical menu placed on the left side. </p>
//                 </div>
//             </div>
//         </div>
        
      

//     </>
//   );
// }

// export default DashboardNavBar;


