import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from "react-router-dom";
import livelong from "../../assets/livelong.png"


function LiveLongPage() {
    let navigate = useNavigate();

  return (
    <>
        <section className="container py-3 py-md-5">
            <div className="container overflow-hidden">
                <div className="row gy-5">
                    <div className="col-12">
                        <div className="row align-items-center flex-row-reverse gy-3 gy-md-0 gx-xl-5">
                        <div className="col-xs-12 col-md-6">
                            <div className="img-wrapper position-relative bsb-hover-push">
                            <a href="#!">
                                <img className="img-fluid rounded w-100 h-100 object-fit-cover" loading="lazy" src={livelong} alt="Food" />
                            </a>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <div>
                            <h2 style={{fontWeight:500}} className="h1 display-5 mb-3"><a className="link-dark text-decoration-none" href="#!">
                                Live Long, Live 
                                <br/>Happy
                            </a></h2>
                            <p style={{fontWeight:500}} className="mb-4">
                              Our Meal Planner keeps track of your eating patterns. Get weekly advice to help you achieve a healthier weight and better mood. This is the key to a long, healthier life.
                            </p>
                            <button onClick={() =>{ navigate("/waitlist")}}  style={{borderColor:'#30B15C', borderWidth: 3, color: '#30B15C', fontWeight:'bold'}} className="btn fs-5 btn-lg btn-outline-secondary rounded-pill px-20" href="#!" target="_self">Join Waitlist</button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>
  );
}

export default LiveLongPage;


