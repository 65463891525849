import 'bootstrap/dist/css/bootstrap.css';
import idealdiet from "../../assets/idealdeit.png";
import { useNavigate } from "react-router-dom";

function ExclusiveRecipePage() {
    let navigate = useNavigate();
    
  return (
    <>
        <section style={{marginTop: -25}} className="container py-3 py-md-5">
            <div className="container overflow-hidden">
                <div className="row gy-5">
                        <div className="col-12">
                            <div className="row align-items-center flex-row-reverse gy-3 gy-md-0 gx-xl-5">
                                <div className="col-xs-12 col-md-6">
                                    <div className="img-wrapper position-relative bsb-hover-push">
                                    <a href="#!">
                                        <img className="img-fluid rounded w-100 h-100 object-fit-cover" loading="lazy" src={idealdiet} alt="Travel" />
                                    </a>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <div>
                                    <h2 style={{fontWeight:500}} className="h1 display-5 mb-3"><a className="link-dark text-decoration-none" href="#!">
                                        Exclusive Recipes 
                                        <br />From African 
                                        <br />Chefs
                                        </a>
                                    </h2>
                                    <p style={{fontWeight:"initial", padding:'10' }} className="mb-4">
                                        Cook better meals for your loved ones. Get access to thousands of premium recipes curated by top African chefs, complete with accurate nutritional information and step-by-step cooking tutorials.
                                        
                                    </p>
                                    <button onClick={() =>{ navigate("/waitlist")}} style={{borderColor:'#30B15C', borderWidth: 3, color: '#30B15C', fontWeight:'bold'}} className="btn fs-5 btn-lg btn-outline-secondary rounded-pill px-20" >Join Waitlist</button>

                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>

        </section>
    </>
  );
}

export default ExclusiveRecipePage;


