import React, { useState} from "react";
import 'bootstrap/dist/css/bootstrap.css';
import forgotPassword from "../../assets/forgotPassword.png";
import NavBar from "../home/NavBar";
import arrowLeft from "../../assets/arrowLeft.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';




function SetNewPasswordPage() {
  // For hide or show password
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisible2, setPasswordVisible2] = useState(false);
  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handlePasswordVisibility2 = () => {
      setPasswordVisible2(!passwordVisible2);
    };
  //End of for hide or show password



  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  
  return (
    <>
      <NavBar />
      <section style={{backgroundColor:'white', marginTop:"6%"}} className="mx-auto  " >
        <div style={{backgroundColor:'white'}}  className="row align-items-center justify-content-center">
          <div style={{backgroundColor:'white'}}  className="col-11 col-md-10 col-xl-4 col-xxl-7">
           
            <form style={{marginTop:"5%"}} 
                // onSubmit={handleSubmit}
            >
              <div style={{backgroundColor:'white' }} className="text-center"  >
                  <img className="text-center" style={{marginTop:40}} src={forgotPassword} alt="Forgot Password" />
              </div>
              <h2 style={{marginTop:30}} className="h3 fw-bold text-center">Set new password</h2>
              <p style={{color:'#474D66', marginTop:30}} className="h6 text-center">
                our new password must be different to the 
                <br />previously used passwords.
              </p>
              
                        
              <div style={{marginTop:10}} className="row gy-3 gy-md-4 overflow-hidden">
                    
                {/*Beginning of Password Input */}
                <div  className="col-12 mt-3">

                  {/* <label style={{color:"#474D66", fontWeight:'normal' }} for="email" className="form-label fw-bold"> Password <span className="text-danger">*</span></label> */}
                  <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                      <label style={{color:"#474D66", fontWeight:'normal' }} for="password" className="form-label fw-bold">Password <span className="text-danger">*</span></label>
                  </div>

                  <div className="input-group">
                      <input type={passwordVisible ? 'text' : 'password'}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              style={{height:50, borderTopLeftRadius:10, }}
                              className="form-control" name="password" id="password" placeholder="***********" required />
                      
                      <span className="input-group-text">
                          <FontAwesomeIcon
                              icon={passwordVisible ? faEyeSlash : faEye}
                              onClick={handlePasswordVisibility}
                          />
                      </span>
                  </div>
                  {/* <input 
                      // value={email}
                    // onChange={(e) => setEmail(e.target.value)}
                    style={{height:50, borderRadius:10, }} placeholder="***********" type="password" className="form-control" name="password" id="password" required 
                  /> */}
              </div>
              {/*End of Password Input */}


              <span style={{marginTop:10, color:"#A73636", fontWeight:600}} className="text-secondary text-danger">Must be at least 8 characters</span>


                {/*Beginning Confirm-Password Input */}
                <div  className="col-12 mt-3">
                  
                  <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                      <label style={{color:"#474D66", fontWeight:'normal' }} for="password" className="form-label fw-bold">Confirm Password <span className="text-danger">*</span></label>
                  </div>

                  <div className="input-group">
                      <input type={passwordVisible2 ? 'text' : 'password'}
                              value={confirmPassword}
                              onChange={(e) => setConfirmPassword(e.target.value)}
                              style={{height:50, borderTopLeftRadius:10, }}
                              className="form-control" name="password" id="password"  placeholder="***********" required />
                      
                      <span className="input-group-text">
                          <FontAwesomeIcon
                              icon={passwordVisible2 ? faEyeSlash : faEye}
                              onClick={handlePasswordVisibility2}
                          />
                      </span>
                  </div>

                </div>
                {/*End of Confirm-Password Input */}

               

                <div className="col-12">
                  <div className="d-grid">
                    <button style={{backgroundColor:'#51D27D', height:50, borderRadius:10, color:'white' }} className="btn bsb-btn-xl fw-bold" type="submit">Reset Password</button>
                  </div>
                </div>

                      
              </div>
            </form>
            <p style={{color:'#30B15C', marginTop:20}} className="h6 text-center">
              <img src={arrowLeft} alt="Back" />
              Back to login
            </p>
           


          </div>
        </div>
      </section>
    </>
      
  );
}

export default SetNewPasswordPage;


